.container.contact_container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 8rem;
}
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem ;
    width: 16rem;
}
.contact-option{
    background: var(--color-bg-varient);
    padding: 1rem;

    text-align: center;
    border-radius: 1.2rem;
    border: 2px solid transparent;
    transition: var(--transition);

}

.contact-option:hover{
    background: transparent;
    border-color: var(--color-primary-varient);
}
.contact_option-icon{
    /* color: var(--color-primery); */
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact-option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}



/* ===============Form============= */

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.2rem;
    background: transparent;
    border-radius: 0.5rem;
    border: 2px solid var(--color-primary-varient);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px ){
    #contact{

        margin-bottom: 30rem;
    }
    
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 600px ){
    .container.contact_container{
        width: var(--container-width-sm);
    }
    #contact{
        margin-top: 15rem;
        margin-bottom: 30rem;
    }
}

@media screen and (max-width: 320px ){
    #contact{
        margin-top: 15rem;
        margin-bottom: 30rem;
    }
    .container.contact_container{
        width: var(--container-width-sm);
        
    }
}