/* Container for centering the title */
#portfolio{
  margin-bottom: 15rem;
}
.title-container {
    text-align: center;
    margin-bottom: 40px; /* Adjust space below the title */
  }
  
  /* Title styling */
  .title-animation {
    display: inline-block;
    font-size: 2.5rem; /* Adjust the size of the title */
    font-weight: bold;
    color: var(--color-white); /* Adjust text color */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow */
    border-right: 4px solid #333; /* Create a typing cursor effect */
    animation: typing 3.5s steps(40, end), blink-caret 1s step-end infinite;
  }
  
  /* Typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  /* Blinking cursor effect */
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: #333;
    }
  }
  
  /* Portfolio container styling */
  .portfolio_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .portfolio_item {
    width: 300px; /* Set the width of the portfolio item */
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .portfolio_item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    width: 70%;
    
  }
  
  .portfolio_item-image {
    position: relative;
    cursor: pointer; /* Change cursor to pointer on hover */
  }

  .video {
    width: 100%; /* Set the video to take up the full width of the portfolio item */
    height: 200px; /* Set the height of the video */
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .portfolio_item:hover .video {
    transform: scale(1.1);
  }
  
  .portfolio_item:hover .video:hover {
    cursor: none; /* Hide cursor when hovering over video */
  }
  
  .portfolio_item h3,
  .portfolio_item h5,
  .portfolio_item .btn {
    position: absolute;
    z-index: 10;
    color: #fff;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .portfolio_item h3 {
    bottom: 70px;
    left: 20px;
  }
  
  .portfolio_item h5 {
    bottom: 50px;
    left: 20px;
  }
  
  .portfolio_item .btn {
    bottom: 10px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }
  
  .portfolio_item:hover h3,
  .portfolio_item:hover h5,
  .portfolio_item:hover .btn {
    opacity: 0;
  }
 
  @media screen and (max-width: 1024px) {
    .title-animation{
      font-size: 1.5rem;
      
    }
    #portfolio{
      margin-top: 30rem;
      margin-bottom: 20rem;
    }
}
@media screen and (max-width: 720px) {
  #portfolio{
    margin-top: 30rem;
    margin-bottom: 60rem;
  }
}
@media screen and (max-width: 600px) {
  .title-animation{
    font-size: 1rem;
    
  }
  #portfolio{
    margin-top: 40rem;
    margin-bottom: 70rem;
  }
}

@media screen and (max-width: 360px) {
  #portfolio{
    margin-top: 53rem;
    margin-bottom: 60rem;
  }
}