
.service_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}
.service{
    background: var(--color-bg-varient);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primery);
    height: fit-content;
    transition: var(--tansition);
}

.service:hover{
    background: transparent;
    border-color: var(--color-primary-varient);
    cursor: default;
}

.service_head{
    background: var(--color-primery);
    padding: 1.2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1 rem rgba(0, 0, 0,0.1);
    
}
.service_head h3{
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service_list{
    padding: 1.5rem;
}

.service_list li{
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service_list-icon{
    color: var(--color-primery);
    margin-top: 0.5rem;
}

.service_list p{
    font-size: 0.9rem;
}

.service_list p:hover{
    transform: scale(1.05);
    color: var(--color-primery);
}


@media screen and (max-width: 1024px ){
    .service_container{
        grid-template-columns: 1fr 1fr;
    }
    .service{
        height: auto;
    }
   }

@media screen and (max-width: 600px ){
    .service_container{
        grid-template-columns: 1fr ;
        gap: 1.5;
    }
}