footer{
    background: var(--color-primery);
    padding: 1rem 0;
    text-align: center;
    margin-top: 7rem;
    font-size: 0.9rem;
}
footer a{
    color: var(--color-bg);
}
.footer_logo{
    font-size: 2rem;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 2rem;
}

.permalinks{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 2rem;
}

.footer_social{
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3rem;
}

.footer_social a{
    background: var(--color-bg);
    color: var(--color-white);
    border-radius: 0.8rem;
    padding: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer_social a:hover{
    background: transparent;
    border-color: var(--color-bg);
    color: var(--color-bg);

}

.footer_copyright{
    margin-bottom: 7rem;
    color: var(--color-bg);
    font-size: large;
    font-weight: 500;
}

@media screen and (max-width: 600px ){
    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
    }
    .footer_social{
        margin-bottom: 2.6rem;
    }
}